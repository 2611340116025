import { globalHistory as history } from "@reach/router";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";
import "../../../styles/pages/_portfolio.scss";
import Section from "./../../_SectionTemplate";

export function PortfolioList(props) {
  const { location } = history;
  const [rendered, setRendered] = useState(7);
  const portfolioPage = location.pathname.startsWith("/portfolio");

  //intersection observer stuff start
  const { rootMargin = "100px", threshold = 0, ...rest } = props;
  const ref = useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: rootMargin,
    threshold: threshold,
  });
  const inView = !(intersection && intersection.intersectionRatio > threshold);
  //intersection observer stuff end

  //img intersection observer stuff start
  const { rootMarginImg = "2px", thresholdImg = 0 } = props;
  const refImg = useRef(null);
  const intersectionImg = useIntersection(refImg, {
    root: null,
    rootMargin: rootMarginImg,
    threshold: thresholdImg,
  });
  const inViewImg = !(
    intersectionImg && intersectionImg.intersectionRatio > thresholdImg
  );
  //intersection observer stuff end

  //update list and update window.scroll
  function updateList() {
    setRendered(rendered + 7);
    typeof window !== "undefined" && window.scroll.update();
  }

  useEffect(() => {
    // console.log("inview", inView)
    console.log("data home", props.data)
  });

  return (
    <>
      {/* <div className="container-fluid"> */}
      <Section desc={`portfolio portfolio-index ${props.home && "hp"}`}>
        <div
          className="row"
          id="portfolio-row"
          data-scroll
          data-scroll-speed="0.5"
        >
          {!props.home && (
            <h1>
              I design and develop high-converting, immersive and award-winning
              digital products (experiences) for awesome brands, individuals and
              businesses.
            </h1>
          )}

          <div
            className={`col portfolio-list ${portfolioPage ? "ppg" : ""}`}
            id="portfolio-list"
            ref={ref}
          >
            {props.data.map(
              (node, index) =>
                (!portfolioPage ? index < 6 : index < rendered) &&
                // (!portfolioPage ? (index + props.show < 8) : (index < rendered)) &&
                (inView ? (
                  <article key={node.id} id={`portfolio-article${index}`}>
                    <div
                      className="portfolio-list-img"
                      data-scroll-target={`#portfolio-article${index}`}
                      data-scroll
                      data-scroll-speed="2"
                    >
                      <div
                        className="portfolio-list-img-inner"
                        data-scroll
                        data-scroll-speed="-0.63"
                        data-scroll-delay="0.07"
                      />
                    </div>
                    <div
                      className="portfolio-list-meta"
                      data-scroll-target={`#portfolio-article${index}`}
                      data-scroll
                      data-scroll-speed="3"
                      data-scroll-delay="0.08"
                    ></div>
                  </article>
                ) : (
                  <article key={node.id} id={`portfolio-article${index}`}>
                    <div
                      className="portfolio-list-img"
                      data-scroll-target={`#portfolio-article${index}`}
                      data-scroll
                      data-scroll-speed="2"
                      ref={refImg}
                    >
                      <div
                        className={`portfolio-list-img-inner ${inViewImg ? "" : "enter"}`}
                        data-scroll
                        data-scroll-speed="-0.63"
                        data-scroll-delay="0.07"

                      >
                        <Link
                          to={node.slug}
                        >
                          {node.frontmatter.videoSourceURL ? (
                            <video width="100%" autoPlay loop muted>
                              <source
                                src={node.frontmatter.videoSourceURL.publicURL}
                                type="video/mp4"
                              />
                            </video>
                          ) : <>
                            <GatsbyImage
                              image={getImage(node.frontmatter.featuredImage)}
                              alt={node.frontmatter.title}
                              loading="lazy"
                              className={inViewImg ? "slide" : ""}
                            />
                            {/* <div className="diamonds"><DiamondOnImage image={node.frontmatter.cover.publicURL} /></div> */}
                          </>}
                        </Link>
                      </div>
                    </div>
                    <div
                      className="portfolio-list-meta"
                      data-scroll-target={`#portfolio-article${index}`}
                      data-scroll
                      data-scroll-speed="3"
                      data-scroll-delay="0.08"
                    >
                      <h2>
                        <Link
                          to={portfolioPage ? node.slug : node.slug}
                        >
                          {node.frontmatter.title}
                        </Link>
                      </h2>
                      <p>
                        <span className="date">{node.frontmatter.date}</span>
                        <span class="desc">{node.frontmatter.description}</span>
                      </p>
                    </div>
                  </article>
                ))
            )}
          </div>
        </div>
        {/* {!portfolioPage ? (
          <MarqueeLink url1="View more of my work" url2="/portfolio" />
        ) : rendered <= props.data.length ? (
          <button className="btn load-more" onClick={() => updateList()}>
            Load More
          </button>
        ) : (
          "bleh"
        )} */}
      </Section>
    </>
  );
}
