import { useEffect } from "react";
import LocomotiveScroll from "locomotive-scroll";
// import "locomotive-scroll/src/locomotive-scroll.scss";
import "../../styles/components/_locomotive.scss"
import { scroll, scrollMobile } from "../../theme";

function useLocoScroll(start) {
  useEffect(() => {
    if (!start) return;
    let locoScroll = null;

    const scrollEl = document.querySelector("#___gatsby");

    locoScroll = new LocomotiveScroll({
      el: scrollEl,
      // smooth: true,
      // smoothMobile: true,
      // lerp: 0.075,
      // multiplier: 1,
      // touchMultiplier: 1,
      // getDirection: true,
      class: "is-reveal",
      // ...scroll.options,
      inertia: 0.8,
      smooth: true,
      getDirection: true,
      smartphone: {
        smooth: true,
      },
      tablet: {
        smooth: true,
      },
    });

    locoScroll.update()

    // Exposing to the global scope for ease of use.
    window.scroll = locoScroll

    const lsUpdate = () => {
      if (locoScroll) {
        locoScroll.update();
      }
    };

    return () => {
      if (locoScroll) {
        locoScroll.destroy();
        locoScroll = null;
      }
    };
  });
}

export default useLocoScroll