import { SEO } from "@components/seo";
import { globalHistory as history } from "@reach/router";
import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import Cursor from "../../components/Cursor/cursor";
import Layout from "../../components/layout";
// import PortfolioStack from './../../components/_pages/portfolio/PortfolioStack';
import { PortfolioList } from "../../components/_pages/portfolio/PortfolioList";
import useLocoScroll from './../../components/_hooks/useLocoScroll';
import "./../../styles/pages/_portfolio.scss";

function PortfolioPage({ data }) {
  // const image = getImage(data.allMdx.node)
  const { location } = history;
  useLocoScroll(true);

  useEffect(() => {
    console.log("data:", data);
  }, [data]);

  return (
    <Layout location={location}>
      {!isMobile && <Cursor />}
      {/* <Scroll callbacks={location} /> */}
      <SEO title="My Portfolio" />

      <div className="row">
        <div className="col-md-12">
          <PortfolioList data={data.allMdx.nodes} />
        </div>
      </div>

    </Layout>
  );
}

export const query = graphql`
  query {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/portfolio/" }
        frontmatter: { category: { eq: "portfolio" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        excerpt
        fields {
          slug
        }
        timeToRead
        frontmatter {
          category
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          featuredImage {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.5)
            }
            name
          }
          featured
        }
      }
    }
  }
`;

export default PortfolioPage;
